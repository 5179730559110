import { LinkProps } from '@remix-run/react';

//
//

export const parseI18nNavigate = (to: LinkProps['to'] | number, lang: string) => {
  if (typeof to === 'number') to; // delta
  if (typeof to === 'string' && /^\./.test(to)) return to; // relative path
  if (typeof to === 'string') return '/' + lang + '/' + String(to).replace(/^\//, ''); // string
  if (typeof to === 'object' && to.pathname)
    return { ...to, pathname: '/' + lang + '/' + String(to).replace(/^\//, '') }; // To object

  return to; // fallback
};
