import { Link, LinkProps } from '@remix-run/react';
import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { parseI18nNavigate } from '~/zidmui/utils';

//

export const I18nLink = memo(
  forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const { i18n } = useTranslation();

    return (
      <Link
        {...props}
        ref={ref}
        to={parseI18nNavigate(props.to, i18n.language) as LinkProps['to']}
      />
    );
  }),
);

I18nLink.displayName = 'I18nLink';
